import React, { Fragment } from 'react';

export const StrategicPlanningPage = () => {
  return (
    <Fragment>
       <h3>Strategic Planning</h3>
        <p><strong>The traditional sector</strong><br />
        The communications model in the past has used a mix of tools to promote a product, and in some cases the tool is the product:<br />
        Advertising<br />
        Marketing/publications<br />
        Public relations/media relations<br />
        Conferences/exhibitions<br />
        Online resources<br />
        Public affairs</p>
        <p><strong>What people want, why they spend time with your product</strong></p>
        <p>The issue is how to communicate with generation Y, those who are only used to electronic communications, as compared to generation X who tend to be in management and used to more non-social marketing channels.</p>
        <p><strong>Changing face of publishing</strong></p>
        <p>The current trend is for traditional sector lead publishers to stop producing just printed magazine or newspapers, and to publish material on their websites.</p>
        <p>The next step is to look at how this step change is integrated into the other areas of work so it is one total campaign, and not a series of individual elements.</p>
        <p>How social networking develops will also play an important role in securing a profile for products and companies. The public sector is only just realising the potential.</p>
        <p>Given the correct product the possibility of working nationally and internationally is tremendous.</p>
        <p><strong>Key point</strong></p>
        <p>Develop strategic media plans, which are incorporated into the communications plans and evaluate the plans on completion.</p>
    </Fragment>
  );
}

export default StrategicPlanningPage;
