import React, { useState, useCallback, Fragment } from 'react';
import { BurgerIcon, Line, BurgerMenu } from './Burger.styled';

export const Burger = () => {
  const [ open, setOpen ] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(prevState => !prevState);
  }, []);

  return (
    <Fragment>
      <BurgerIcon onClick={handleClick} animate={open}>
        <Line />
        <Line />
        <Line />
      </BurgerIcon>
      <BurgerMenu open={open}>
        <a href="/">Home</a>
        <a href="/about/">About</a>
        <a href="/media-campaigns/">Media Campaigns</a>
        <a href="/strategic-planning/">Strategic Planning</a>
        <a href="mailto:paulbailey123@googlemail.com" target="_blank" rel="noreferrer">Contact</a>
      </BurgerMenu>
    </Fragment>
  );
}

export default Burger;
