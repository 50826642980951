import styled from 'styled-components';

export const Wrapper = styled.div`
  font-family: Helvetica,Arial,sans-serif;
  line-height: 1.3em;
  letter-spacing: .03em;
  -webkit-font-smoothing: antialiased;
  color: #353535;
  h1 {
    font-size: 2.8em;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    line-height: 1em;
  }
  p {
    margin: 0 0 1em;
  }
`;

export const Paper = styled.div`
  max-width: 700px;
  box-shadow: 0 1px 5px RGBA(0,0,0,.1);
  padding: 40px;
  background-color: #FFF;
  margin: 0 auto;
  z-index: 1;
  position: relative;
`;

export const ImgRight = styled.img`
  float: right;
  margin: .375em 0 1.75em 1.75em;
`;