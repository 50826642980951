import styled, { css } from 'styled-components';

export const BurgerIcon = styled.div`
  width: 26px;
  height: 32px;
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 3;
  ${({ animate }) =>
    animate &&
    css`
      div:nth-child(1) {
        animation-delay: .3s;
        transform: rotate(45deg);
        margin: 6px 0 0;
        background-color: #353535;
      }
      div:nth-child(2) {
        animation-delay: .15s;
        opacity: 0;
        margin: 0;
      }
      div:nth-child(3) {
        animation-delay: 0s;
        transform: rotate(-45deg);
        margin: -6px 0 0;
        background-color: #353535;
      }
  `}
`;

export const Line = styled.div`
  width: 100%;
  height: 3px;
  background-color: #FFF;
  margin: 0 0 6px;
  border-radius: 99px;
  transform-origin: 50% 50%;
  transition: transform 1s,margin 1s,background-color 1s,opacity .5s;
  transition-timing-function: cubic-bezier(.075,.82,.165,1);
`;

export const BurgerMenu = styled.div`
  position: fixed;
  width: 300px;
  height: 100%;
  top: 0;
  right: -300px;
  background-color: #fff;
  padding: 44px 0 0;
  transition: right 1s;
  transition-timing-function: cubic-bezier(.075,.82,.165,1);
  box-shadow: 1px 0 3px RGBA(0,0,0,.05) inset;
  z-index: 2;
  a {
    display: block;
    color: #000;
    text-decoration: none;
    padding: 20px;
  }
  ${({ open }) =>
    open &&
    css`
      right: 0px;
  `}
`;