import styled from 'styled-components';

export const TopMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  box-shadow: 0 1px 2px RGBA(0,0,0,.3);
  z-index: 2;
  background-color: #FFF;
  @media (max-width: 767px) {
    display: none; 
  }
  a {
    color: #868686;
    text-decoration: none;
    padding: 12px 15px;
    display: inline-block;
  }
`;

export const Banner = styled.header`
  height: 400px;
  background-color: #eaea85;
  margin: 0 0 -50px;
  background-size: cover;
  background-position: center;
  background-image: url('/IMG_1702.jpg');
`;

export const Overlay = styled.div`
  position: absolute;
  background-color: RGBA(0,0,0,.4);
  width: 100%;
  height: 400px;
  top: 0;
  z-index: 0;
`;

export const Title = styled.div`
  max-width: 700px;
  padding: 160px 0 0;
  color: #FFF;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
  position: relative;
`;