import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from '../HomePage';
import AboutPage from '../AboutPage';
import MediaCampaignsPage from '../MediaCampaignsPage';
import StrategicPlanningPage from '../StrategicPlanningPage';
import Header from '../Header';
import { Wrapper, Paper } from './Page.styled';
import Burger from '../Burger';

export const Page = () => {
  return (
    <Wrapper>
      <Burger />
      <Header />
      <Paper>
        <Router>
          <Switch>
            <Route path="/strategic-planning">
              <StrategicPlanningPage />
            </Route>
            <Route path="/media-campaigns">
              <MediaCampaignsPage />
            </Route>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </Router>
      </Paper>
    </Wrapper>
  );
}

export default Page;