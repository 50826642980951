import React, { Fragment } from 'react';
import { ImgRight } from '../Page/Page.styled';

export const AboutPage = () => {
  return (
    <Fragment>
      <ImgRight src="/NewDesigners_37DE_Page_1-1-212x300.jpg" />
      <h3>About</h3>
      <p>My professional career has covered both the creative industries and the public sector. Starting with the BBC as a production designer later moving to the University of Manchester TV Service. From there I moved into the private sector running a publicity consultancy with clients in the public arts sector and private sector clients. Later I was invited to join the team opening a new theatre in Kent as the marketing manager. Then invited by the local authority to establish a new public relations office. Following this I moved to a London authority as their Head of Public Relations. The last move was to a national organisation as media manager in the public sector.</p>
      <p><strong>Areas of expertise</strong></p>
      <p>Media Relations Manager with experience of public relations strategy, and line management of staff. My specific skills are in media relations, editorial content for web and print, web conferencing, marketing, event organisation, design and project management.</p>
      <p><strong>Management expertise</strong></p>
      <ul>
      <li>Line management of various communications specialists, as well as commissioning external communications suppliers. Setting staff objectives, supporting staff development and annual appraisals.</li>
      <li>Led on the marketing and PR activities for local authorities at a local, regional and national level. Strategic role in the corporate/business plans for organisations, and taken responsibility for the successful outcomes of targets and analysis.</li>
      <li>Managed the media element of 33 individual programmes of work, with 12 being government contracts, having specific targets and deadlines, and often working with Government Ministers. Producing the forward plans, prioritising work, and staying in contact with programme managers. Attending events and conferences to organise the media/ photographers and brief people for interviews.</li>
      <li>Previous experience in the arts and music industry in the North West. Clients included Northern Ballet Theatre, Manchester City Art Gallery, Whitworth Art Gallery, Manchester University, Royal Exchange Theatre, Palace Theatre and Kennedy Street Enterprises.</li>
      <li>Presentation skills range from reporting to management boards, representing the organisation at stakeholder meetings and trade exhibitions/conferences.</li>
      </ul>
    </Fragment>
  );
}

export default AboutPage;
