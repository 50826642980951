import React, { Fragment } from 'react';
import { ImgRight } from '../Page/Page.styled';

export const MediaCampaignsPage = () => {
  return (
    <Fragment>
      <ImgRight src="/Rose-Hill-Designs-postcard-214x300.jpg" />
      <h3>Media Campaigns</h3>
      <p>Media these days can mean many different things, and has no geographic boundaries.</p>
      <p>What is important is a clear set of objectives and to be able to evaluate results.</p>
      <p>A good communications plan will include a media element that aims to profile and inform what the campaign aims to achieve. To achieve this all elements of the media should be considered and prioritized. Often the plan will include joint working with other organisations.</p>
      <p>The key to any plan though is advance planning and direct contact with members of the media.</p>
    </Fragment>
  );
}

export default MediaCampaignsPage;
