import React from 'react';
import { TopMenu, Banner, Overlay, Title } from './Header.styled';

export const Header = () => {
  return (
    <div>
      <Overlay />
      <TopMenu>
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/media-campaigns">Media Campaigns</a>
        <a href="/strategic-planning">Strategic Planning</a>
        <a href="mailto:paulbailey123@googlemail.com" target="_blank" rel="noreferrer">Contact</a>
      </TopMenu>
      <Banner>
        <Title>
          <h1>Paul Bailey</h1>
          <h2>Creative Sector Public Relations</h2>
        </Title>
      </Banner>
    </div>
  );
}

export default Header;
